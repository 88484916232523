import React from "react";
import { initGlobalState } from "./state";



const GlobalScope = () => {
    initGlobalState();
};

export default GlobalScope;
