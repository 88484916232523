import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashLayout from "../../../../layouts/DashLayout";
import { LessonButton} from "../../../../components/Buttons";
import { RiArrowUpSLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import { getSubjectLessons } from "../../../../services/authServices";
// import LessonItem from "../../lessons/components/LessonItem";
// import AddLessonsCard from "../../lessons/components/AddLessonsCard";
import CenterModal from "../../../../components/CenterModal";
import UploadLessonModal from "../../lessons/components/UploadLessonModal";

function SubjectLessonsDetails() {
  const { id } = useParams();
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const [lesson, setLesson] = useState<any[]>([]);
  // const [selectedItem, setSelectedItem] = useState();
  const [active, setActive] = useState(false);
  const navigate = useNavigate();

  const config = configure(userInfo.token);
  const fetchLessons = async (id: any) => {
    setLoading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (!localStorage.getItem("token")) {
      setLoading(false);
      alertActions.error("Your session has ended please login");
      navigate("/login");
    }
    await getSubjectLessons(id, config)
      .then((res) => {
        if (!res.data.error) {
          setLesson(res.data.data);
          // dispatch(schoolActions.saveTeacherStudents(res?.data.data));
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    fetchLessons(id);// eslint-disable-next-line
  }, [id]);
  //   const validate = async () => {
  //     setloading(true);
  //     if (!pass || !cpass || pass !== cpass) {
  //       alertActions.error(
  //         "Please enter password, Confirm password should be correct"
  //       );
  //       setloading(false);
  //       return;
  //     }
  //     if (pass.length < 6) {
  //       alertActions.warning("Please ensure password is atleast 6 characters");
  //       setloading(false);
  //       return;
  //     }

  //     try {
  //       const resetpass = await changePass({ email, password: pass });
  //       if (resetpass.status == 200) {
  //         setloading(false);
  //         alertActions.success("Password reset successful");
  //         navigate("/login");
  //       }
  //     } catch (error: any) {
  //       error?.message == "Network Error" &&
  //         alertActions.error("Network Error, Please check your internet");

  //       setloading(false);
  //     }
  //   };
  return (
    <>
      <DashLayout>
      <div className="w-full h-screen">
        <div className="flex justify-between">
          <div>
            <p className="text-2xl font-medium">Basic Science </p>
            <p className="mt-2 text-sm text-[#87909E]">
              To provide fundamental understanding of natural phenomena and
              processes.
            </p>
          </div>
          <div className="w-36 rounded-lg p-5 py-3 bg-[#E6E6FA] border border-[#4F46E5]">
            <p className="font-medium">Primary 5</p>
            <p className="text-[#87909EBD]">ARM A</p>
          </div>
        </div>
        <div className="shade rounded-lg lg:w-10/12 mt-6">
          <div className="p-5 bg-[#F6F6F6]">
            <p className="text-2xl font-medium">1st Term</p>
          </div>
          <div className="p-5 border-b border-[#E3E2FF]">
            <p className="text-[#87909E]">Introduction to English</p>
          </div>
          <div className="p-5 min-h-[300px] overflow-y-scroll">
            <div className="grid grid-cols-3 gap-5">
              {/* {lesson &&
                !!lesson.length &&
                lesson.map((item, index) => <LessonItem setTimeline item={item} index={index} />)} */}
                {/* <AddLessonsCard onClick={() => setActive(true)}/> */}
            </div>
          </div>
          <div className="p-5 border-t border-[#E3E2FF] flex items-center justify-between">
            <div>
              <LessonButton
                text="Set Test/Exam"
                altClassName="bg-[#4F46E5] rounded px-3 py-2 text-white"
              />
            </div>
            <RiArrowUpSLine className="text-2xl" />
          </div>
        </div>
      </div>
    </DashLayout>
    <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <UploadLessonModal close={() => setActive(false)} id={30} />
      </CenterModal>
    </>
  );
}

export default SubjectLessonsDetails;
