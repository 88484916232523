
export const EmptyCart = ({size, msg}:{size:number, msg:string}) => {
    return (
        <div className="w-full">
            <img src="https://res.cloudinary.com/dic7regoe/image/upload/v1696062805/praxeum/cart_mljnah.webp" alt="empty" width={size} className="mx-auto" />
            <p className="mt-3 font-[500] text-center">{msg}</p>
        </div>
    )
}

export const EmptyShelf = ({size, msg}:{size:number, msg:string}) => {
    return (
        <div className="w-full">
            <img src="https://res.cloudinary.com/dic7regoe/image/upload/v1696065734/praxeum/shelf-removebg-preview_1_r9zh5e.png" alt="empty" width={size} className="mx-auto" />
            <p className="mt-3 font-[500] text-center">{msg}</p>
        </div>
    )
}