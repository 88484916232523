import React, { useEffect, useRef, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { RiDeleteBin5Line, RiEdit2Line } from "react-icons/ri";
import {
  getSchoolTeachersClass,
  getSchoolTeachersSubject,
} from "../../../../services/authServices";
import { useDispatch } from "react-redux";
import { schoolActions } from "../../../../store/schoolSlice";
import { configure } from "../../../../services/config";

interface TeacherCardProps {
  del?: any;
  subjects?: () => void;
  classes?: () => void;
  edit?: any;
  teacher: any;
  newchange: any;
}

const TeacherCard: React.FC<TeacherCardProps> = ({
  edit,
  teacher,
  subjects,
  classes,
  del,
  newchange,
}) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [teachersSubjects, setTeachersSubjects] = useState([]);
  const [teachersclass, setTeachersclass] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    const config = configure(localStorage.getItem("token"));

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [newchange]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function getInitials(name: string | undefined): string {
    const parts: string[] | undefined = name?.split(" ");
    if (!parts || parts.length === 0) {
      return ""; // or any other desired behavior for empty or undefined name
    }
    return parts
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  }

  const initials: string = getInitials(teacher.name);
  return (
    <div className="w-full rounded-[8px] flex flex-col overflow-hidden shadow-md border border-black border-opacity-10 p-3">
      <div className="flex w-full items-center justify-between">
        <div
          className={`h-[48px] w-[48px] text-[18px]"
        } rounded-[50%] bg-[#E3E2FF] border-opacity-20 flex items-center justify-center font-mulish text-[#030036] opacity-70 font-medium`}
        >
          {initials}
        </div>
        <div className="relative" ref={dropdownRef}>
          <div
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown();
            }}
          >
            <div className="cursor-pointer">
              <HiDotsVertical className="text-[#87909E] opacity-70 text-[22px]" />
            </div>
            {isOpen && (
              <div
                className={`absolute right-[-3px] w-[120px] mt-3 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-[8px] shadow-lg py-1 z-50 ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                <div className="w-full divide-y-1 divide-gray-100 cursor-pointer">
                  <div
                    className="flex items-center px-5 py-2  opacity-50 hover:bg-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDropdown();
                      edit();
                    }}
                  >
                    <RiEdit2Line size={20} className="mr-2 text-[#87909E]" />
                    <span className="text-[#030036] opacity-90 text-[14px]">
                      {" "}
                      Edit
                    </span>
                  </div>
                </div>
                <div className="w-full divide-y-1 divide-gray-100 cursor-pointer">
                  <div
                    className="flex items-center px-5 py-2  opacity-50 hover:bg-gray-100"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleDropdown();
                      del();
                    }}
                  >
                    <RiDeleteBin5Line
                      size={20}
                      className="mr-2 text-[#87909E]"
                    />
                    <span className="text-[#030036] opacity-90 text-[14px]">
                      {" "}
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="pt-2 pb-5 border-b border-gray-200">
        <h3 className="font-medium text-[#030036] opacity-70">
          {teacher.Account.name}
        </h3>
      </div>
      <div className="bg-white w-full grid grid-cols-2 gap-2 pt-2">
        <div
          className="flex items-start justify-start flex-col hover:rounded-[4px] hover:bg-opacity-40 hover:bg-gray-200 p-2 cursor-pointer"
          onClick={subjects}
        >
          <h4 className="text-[14px] text-[#87909E] text-normal">Subjects</h4>
          <p className="py-1 text-black text-[14px] leading-[21px]">
            {teacher.Subjects?.length}
          </p>
        </div>
        <div
          className="flex items-start justify-start flex-col hover:rounded-[4px] hover:bg-opacity-40 hover:bg-gray-200 p-2 cursor-pointer"
          onClick={classes}
        >
          <h4 className="text-[14px] text-[#87909E] text-normal">Class</h4>
          <p className="py-1 text-black text-[14px] leading-[21px]">
            {teacher.Classes?.length}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
