import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  InputField,
  MultiSelectField,
  SelectField,
  OptionField,
} from "../../../../components/Inputs";
import { classData, subjectData } from "../../../../data/data";
import { PrimaryButton } from "../../../../components/Buttons";
import { useSelector } from "react-redux";
import { parseDataForMultiSelect } from "../../../../components/multiSelectField";
import { ClassArmSelectField } from "../../../../components/ClassArmselect";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import { updateUser } from "../../../../services/authServices";
import CenterModal from "../../../../components/CenterModal";
import DiscardWarning from "../../../../components/DiscardWarning";

interface EditTeachersProps {
  close: () => void;
  item: any;
  didchange: () => void;
}

const data = [
  {
    id: 1,
    name: "Subject Teacher",
  },
  {
    id: 2,
    name: "Class Teacher",
  },
];
const EditTeachers: React.FC<EditTeachersProps> = ({
  close,
  item,
  didchange,
}) => {
  const [checkedId, setCheckedId] = useState("");
  const [fname, setfname] = useState(item.Account.name);
  const [email, setemail] = useState(item.Account.email);
  const [loading, setloading] = useState(false);
  const [haschanged, sethaschanged] = useState(false);
  const [active, setActive] = useState(false);

  const [classarr, setclassarr] = useState<any>([]);
  const [subdata, setsubData] = useState([]);
  const classInfo = useSelector((state: any) => state.class.classInformation);
  const token = useSelector((state: any) => state.auth.token);
  const config = configure(token);
  const subjectInfo = useSelector((state: any) => state.school.schoolSubject);
  const userInfo = useSelector((state: any) => state.auth.userInformation);

  const docheckedit = () => {
    if (haschanged) {
      setActive(true);
    } else {
      close();
    }
  };
  const handleEdit = async () => {
    setloading(true);
    // check if taken is lost in reduc then use token from local storage
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    const userOBJ = {
      name: fname,
      email,
    };

    try {
      const update = await updateUser(item.Account.id, userOBJ, config);
      console.log(update);
      if (update.status == 200) {
        alertActions.success("User updated successfully");
        setloading(false);
        close();
        didchange();
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <div className="flex items-start flex-col justify-start">
            <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
              Edit Teachers
            </h3>
            <p className="text-[#87909E] font-medium text-[14px]">
              {item.Account.name} (Subject Teacher)
            </p>
          </div>
          <div className="cursor-pointer" onClick={docheckedit}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="p-[24px] border-b-[1px] h-[190px] overflow-y-auto scrollbar-track-blue-200 scrollbar-thumb-blue-500 multi-scrol">
          <InputField
            type="text"
            label="Full Name"
            placeholder=""
            name="fullname"
            variant="white"
            value={fname}
            onChange={(e: any) => {
              setfname(e.target.value);
              sethaschanged(true);
            }}
          />
          <InputField
            type="email"
            label="Email"
            placeholder=""
            name="email"
            variant="white"
            value={email}
            onChange={(e: any) => {
              setemail(e.target.value);
              sethaschanged(true);
            }}
          />
          {/* <OptionField
          label="I am Inviting a"
          checkedId={checkedId}
          onCheckboxChange={handleCheckboxChange}
          variant="white"
          data={data}
        />
        <MultiSelectField
          label="Assign to class"
          variant="white"
          data={parsedClassInfo}
          onSelectedDataChange={handleSelectedDataChange}
        />
        <ClassArmSelectField
          label="Subject Class"
          variant="white"
          data={subjectInfo}
          onSelectedDataChange={handleSubData}
        /> */}
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text={loading ? "Saving..." : "Save"}
            variant="dash"
            onClick={handleEdit}
          />
        </div>
      </div>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <DiscardWarning handleAction={close} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default EditTeachers;
