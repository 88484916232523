import React from "react";
import {
  RiSettings3Line,
  RiHome5Line,
  RiBookLine,
  RiFileListLine,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import { GiTeacher } from "react-icons/gi";
import { FiUsers, FiBookOpen, FiHelpCircle } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import logo from '../assets/images/logo.png'


interface ItemProps {
  icon: any;
  route: any;
label: string
}


const Sidebar = () => {
  const location = useLocation();
	const navigate = useNavigate();
  const user = useSelector((state: any) => state.auth.userInformation.account_type)
	
const Item: React.FC<ItemProps> = ({ icon, route, label }) => {
  const Icon = icon;
  return (
    <div
      className={`py-3 w-full flex items-center justify-center px-8 cursor-pointer my-4 ${
        location.pathname === route
          ? "rounded-[8px] border-l-[4px] border-[#5A51E6]  bg-[#5A51E6] bg-opacity-10"
          : ""
      }`}
      onClick={() => navigate(route)}
    >
      <div className="flex items-center w-full">
        <Icon
          size={23}
          className={`${
            location.pathname === route ? "text-[#4F46E5]" : "text-[#87909E]"
          }`}
        />
        <h3
          className={`${
            location.pathname === route ? "text-[#4F46E5]" : "text-[#87909E]"
          } text-[16px] font-normal pl-3 font-inter`}
        >
          {label}
        </h3>
      </div>
    </div>
  );
};
// school nav list
const renderSchoolNavlist = () => {
  return (
    <div className="flex flex-col justify-between w-full h-full">
        <ul className=" px-6">
          <li>
            <Item icon={RiHome5Line} label="Home" route="/" />
          </li>
          <li>
            <Item
              icon={RiBookLine}
              label="Subjects"
              route="/subjects"
            />
          </li>
          <li>
            <Item icon={GiTeacher} label="Teachers" route="/teachers" />
          </li>
          <li>
            <Item icon={FiUsers} label="Students" route="/students" />
          </li>
          <li>
            <Item icon={FiBookOpen} label="Lessons" route={"/lessons"} />
          </li>
        </ul>
        <div className=" cursor-pointer bottom-0 absolute">
          <Item
            icon={RiSettings3Line}
            label="Settings"
            route="/teacher-profiles"
          />
          <Item
            icon={FiHelpCircle}
            label="Help/Support"
            route="/teacher-profiles"
          />
        </div>
      </div>
  )
}
// teacher nav list
const renderTeacherNavlist = () => {
  return (
    <div className="flex flex-col justify-between w-full h-full">
        <ul className=" px-6">
          <li>
            <Item icon={RiHome5Line} label="Home" route="/" />
          </li>
          <li>
            <Item
              icon={RiBookLine}
              label="Subjects"
              route="/teacher/subjects"
            />
          </li>
          <li>
            <Item icon={FiUsers} label="Students" route="/teacher/students" />
          </li>
          <li>
            <Item icon={FiBookOpen} label="Lessons" route={"/teacher/lessons"} />
          </li>
        </ul>
        <div className=" cursor-pointer bottom-0 absolute">
          <Item
            icon={RiSettings3Line}
            label="Settings"
            route="/teacher/profile"
          />
          <Item
            icon={FiHelpCircle}
            label="Help/Support"
            route="/teacher-profiles"
          />
        </div>
      </div>
  )
}

  return (
    <div className="border-r bg-white border-[#030036] border-opacity-10 shadow relative w-full h-screen max-w-full overflow-auto hideScroll">
      <div className="bg-white sticky top-0 h-[80px] w-full flex items-center pl-8">
        <img className="h-[90px] px-[10px] py-[10px]" src={logo} alt="logo"/>
      </div>
      {
        user === "school" || user === "freelancer" ?
          renderSchoolNavlist()
          : user === "teacher" ? renderTeacherNavlist() : ""
      }
    </div>
  );
};

export default Sidebar;
