import React from "react";
// interface Mess {
//   message: string;
// }
function LoadingCompo() {
  return (
    <div className="w-full flex content-center">
      {" "}
      <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[18px] text-center">
        Loading...
      </h3>
    </div>
  );
}

export default LoadingCompo;
