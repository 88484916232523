import React, { FC, useEffect, useState } from "react";
import { addToCart, getTioClasses } from "../../../../../api";
import { alertActions } from "../../../../../components/alertActions";
import { IoMdClose } from "react-icons/io";
import { PrimaryButton } from "../../../../../components/Buttons";
import CenterModal from "../../../../../components/CenterModal";
import DiscardWarning from "../../../../../components/DiscardWarning";
import { TioClassType } from "../../../../../typing/misc";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { DotsSpinner } from "../../../../../utilities/Loaders/Loader";

interface Props {
  item: any;
  close: () => void;
  update: () => void
}
const SelectClass: FC<Props> = ({ item, close, update }) => {
  const [grades, setGrades] = useState<TioClassType[]>(item.classes);
  const [backDrop, setBackDrop] = useState(false);
  const [selectedClass, setSelectedClass] = useState<any[]>([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [tabs, setTabs] = useState(1);
  const subjects = useSelector((state: any) => state.school.schoolSubject);
  const [isBusy, setIsBusy] = useState(false)
  const handleSelectSubject = (item: any) => {
    setSelectedSubject(item.target.value);
  };
  const handleSelect = (item: any) => {
    setSelectedSubject(item);
    let arms = [...selectedClass];
    if (item.target.checked) {
      arms = [...selectedClass, Number(item.target.value)];
    } else {
      arms.splice(selectedClass.indexOf(Number(item.target.value)), 1);
    }
    console.log(arms);
    
    setSelectedClass(arms);
  };
  const handleNext = () => {
    setTabs(2);
  };
  const HandleClose = () => {
    setBackDrop(false);
    close();
  };
  const getNaming = () => {
    const name = subjects.filter((where:any) => where.id === Number(selectedSubject))
    return name[0].naming
  }
  const addItemsToCart = () => {
    setIsBusy(true)
    const payload = {
      items: "textbook",
      item: {
        textbook: item.id,
        subject_pair: selectedSubject,
        grades: selectedClass
      },
      more: {
        textbook_naming: item.naming,
        class_naming: "All arms",
        subject_naming: getNaming(),
      }
    }
    addToCart(payload)
    .then((res) => {
      if(!res.error){
        alertActions.success(res.message)
        update()
        setIsBusy(false)
        close()
      } else {setIsBusy(false); alertActions.error(res.message);}
    })
    .catch((err) => {
      setIsBusy(false)
      alertActions.error(err.data.message);
    });
  }
  
  return (
    <>
      <div>
        {tabs === 1 && (
          <div>
            <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
              <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
                Select Class
              </h3>
              <div className="cursor-pointer" onClick={() => setBackDrop(true)}>
                <IoMdClose className="text-[22px] text-[#545454]" />
              </div>
            </div>
            <div className="max-h-[350px] pt-4 px-[24px] pb-6 overflow-y-scroll">
              {grades &&
                grades.map((item: TioClassType) => (
                  <div
                    className="flex gap-x-2 mb-3 px-4 py-3 rounded input-shade"
                    key={item.id}
                  >
                    <input
                      type="checkbox"
                      name="class"
                      value={item.id}
                      checked={selectedClass.includes(item.id)}
                      className="w-6 h-6 mt-1"
                      onChange={handleSelect}
                    />
                    <div>
                      <label className="block text-[17px]">{item.naming}</label>
                    </div>
                  </div>
                ))}
            </div>
            <div className="p-[24px] border-t bg-white">
              <PrimaryButton
                text={"Continue"}
                variant="dash"
                onClick={handleNext}
                invalid={!selectedClass}
              />
            </div>
          </div>
        )}
        {tabs === 2 && (
          <div>
            <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
              <div className="flex items-center gap-x-2">
                <p
                  className="w-9 h-9 shadow-lg cursor-pointer rounded-[40px] border border-[#4F46E5] grid place-content-center"
                  onClick={() => setTabs(1)}
                >
                  <HiArrowNarrowLeft className="text-xl" />
                </p>
                <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
                  Pair a subject in your school
                </h3>
              </div>
              <div className="cursor-pointer" onClick={() => setBackDrop(true)}>
                <IoMdClose className="text-[22px] text-[#545454]" />
              </div>
            </div>
            <div className="max-h-[350px] pt-4 px-[24px] pb-6 overflow-y-scroll">
              {subjects &&
                subjects.map((item: any) => (
                  <div
                    className="flex gap-x-2 mb-3 px-4 py-3 rounded input-shade"
                    key={item.id}
                  >
                    <input
                      type="radio"
                      name="class"
                      value={item.id}
                      className="w-6 h-6 mt-1"
                      onChange={handleSelectSubject}
                    />
                    <div>
                      <label className="block text-[17px]">{item.naming}</label>
                    </div>
                  </div>
                ))}
            </div>
            <div className="p-[24px] border-t bg-white">
              <PrimaryButton
                text={isBusy? <DotsSpinner/> : "Add to cart"}
                variant="dash"
                onClick={addItemsToCart}
                invalid={!selectedClass}
              />
            </div>
          </div>
        )}
      </div>
      <CenterModal isOpen={backDrop} onClose={() => setBackDrop(false)}>
        <DiscardWarning close={close} handleAction={HandleClose} />
      </CenterModal>
    </>
  );
};

export default SelectClass;
