import { createSlice } from "@reduxjs/toolkit";

const librarySlice = createSlice({
  name: "library",
  initialState: {
    cart: []
  },
  reducers: {
    saveCart(state, action) {
      state.cart = action.payload;
    },
}
});
export const libraryActions = librarySlice.actions;
export default librarySlice;
