import React, { useEffect, useState } from "react";
import UploadLessonModal from "../../lessons/components/UploadLessonModal";
// import { classes } from "../../../../data/data";
import LessonCard from "../../lessons/components/LessonCard";
import DashLayout from "../../../../layouts/DashLayout";
import CenterModal from "../../../../components/CenterModal";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import { getTeacherLessons } from "../../../../services/authServices";

function TeacherLessons() {
 const [active, setActive] = useState(false);
   const [activeTab, setActiveTab] = useState(0);
   const [item, setItem] = useState("");
   const userInfo = useSelector((state: any) => state.auth.userInformation);
  const [loading, setLoading] = useState(false);
  const [lessons, setLessons] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState()
  const navigate = useNavigate();

  const config = configure(userInfo.token);
  const fetchLessons = async() => {
    setLoading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (!localStorage.getItem("token")) {
      setLoading(false);
      alertActions.error("Your session has ended please login");
      navigate("/login");
    }
    await getTeacherLessons(userInfo.id,config)
    .then((res) => {
      if(!res.data.error){
        setLessons(res.data.data)
        // dispatch(schoolActions.saveTeacherStudents(res?.data.data));
      }
    })
    .catch((err)=> {})
  }
  const gotoLessonDetails = (id:any) => {
    navigate(`/teacher/lessons/${id}`)
  }
  useEffect(() => {
    fetchLessons()
  }, [])

    const toggleTab = (item: any, tabId: number): void => {
      setActive(true);
      setActiveTab(tabId);
      setItem(item);
    };

const colors: string[] = [
  "bg-[#AE1E56]",
  "bg-[#776DB4]",
  "bg-[#148CFA]",
  "bg-[#DDA0DD]",
  "bg-[#681AB0]",
  "bg-[#330072]",
  "bg-[#4F46E5]",
];
  return (
    <>
      <DashLayout title="Teacher">
        <div className="w-full mx-auto">
          <div className="w-full flex items-start justify-start flex-col mt-[20px]">
            <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[22px]">
              Subjects
            </h3>

            <div className="mt-[32px] w-full grid grid-cols-3 gap-[24px]">
              {lessons && !!lessons.length && lessons.map((item: any, index) => {
                const colorIndex = index % colors.length;
                const color = colors[colorIndex];

                return (
                  <LessonCard
                    key={index}
                    item={item}
                    subject={item.class}
                    desc="This subject focuses on the methods of proper articulation, vocal tone, pitch, pace, and resonance"
                    color={color}
                    upload={() => toggleTab(item, 1)}
                    openLesson={() => gotoLessonDetails(item.id)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </DashLayout>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <UploadLessonModal close={() => setActive(false)} id={31} />
      </CenterModal>
    </>
  );
}

export default TeacherLessons;
