import React, { FC, useState } from "react";
import CenterModal from "../../../../../components/CenterModal";
import SelectClass from "./SelectClass";
import { MdOutlinePreview } from "react-icons/md";
import { IoCartOutline } from "react-icons/io5";
import { alertActions } from "../../../../../components/alertActions";

interface Props {
  data: any[];
  updateCart: () => void
}
const LibraryCard: FC<Props> = ({ data, updateCart }) => {
  const urls: string[] = [
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_rwdnty.png",
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_1_fxmypx.png",
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_4_zaha0r.png",
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_3_jsyixp.png",
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_2_ttp48d.png",
    "https://res.cloudinary.com/dic7regoe/image/upload/v1695875299/praxeum/Frame_1000004933_5_ox9cvf.png",
  ];
  const [active, setActive] = useState(false);
  const [shadowLink, setShadowLink] = useState('')
  const [selectedItem, setSelectedItem] = useState<any>();

  const openClassSelect = (item:any) => {
    setSelectedItem(item)
    setActive(true)
  }
  const openSelectedShadow = (item:any) => {
    setShadowLink(item.id)
  }
  const openPreview = () => {
    alertActions.warning("This function is not availablle")
  }
  return (
    <>
      <div className="grid lg:grid-cols-4 gap-8">
        {data &&
          !!data.length &&
          data.map((item, i) => {
            const imgIndex = i % urls.length;
            const imgs = urls[imgIndex];
            return (
              <div
                className="h-[270px] relative grid content-between lib-box rounded-[8px] overflow-hidden hover:translate-y-[-8px] duration-100 ease-in"
                key={i}
                onMouseEnter={() => openSelectedShadow(item)}
                onMouseLeave={() => setShadowLink('')}
              >
                <div>
                  <img src={imgs} alt="img" className="w-full" />
                  <div className="px-3 py-3">
                    <p className="text-[18px]">{item.naming}</p>
                  </div>
                </div>
                {shadowLink === item.id && <div className="absolute top-0 left-0 w-full h-full py-3 px-3 w-full bg-cover animate-cover flex gap-x-4 items-center justify-center">
                  <button className="text-white text-center hover:text-gray-400" onClick={openPreview}>
                    <MdOutlinePreview className="text-3xl mx-auto"/>
                    <span className="text-sm">Preview book</span>
                  </button>
                  <button className="text-white text-center  hover:text-gray-400" onClick={() => openClassSelect(item)}>
                    <IoCartOutline className="text-3xl mx-auto"/>
                    <span className="text-sm">Add to cart</span>
                  </button>
                </div>}
              </div>
            );
          })}
      </div>
      <CenterModal isOpen={active} onClose={() => setActive(false)}>
        <SelectClass close={() => setActive(false)} item={selectedItem} update={updateCart}/>
      </CenterModal>
    </>
  );
};

export default LibraryCard;
