import Request from "./request";
import {
  AddLessons,
  AddLessonsArms,
  SubjectWithLessonCount,
  UpdateLessonTime,
  addBooksToSch,
  addCart,
  addGenerated,
  allClasses,
  allSchoolClasses,
  allSubjects,
  assignClassesToSubject,
  changeStatus,
  classArmStudents,
  classArmSubjects,
  clearCart,
  deleteLesson,
  editSlideInfo,
  fetchCart,
  fetchClassArmsByClassId,
  fetchDefaultClass,
  fetchLibraryBooks,
  fetchSchoolProfile,
  fetchSchoolTeachers,
  fetchSubBooks,
  generationLesson,
  getClassLevels,
  getClassTerms,
  getGenerated,
  getLessons,
  inviteBulkStudentsUrl,
  lessonTemplate,
  promptGenerated,
  removeCart,
  removeClassesFromSubject,
  removeSubjectBook,
  saveFile,
  studentInviteUrl,
} from "./routes";

/* ----------------------------- SCHOOL HANDLERS ---------------------------- */
export const loadSchoolProfile = async () => Request.get(fetchSchoolProfile());

/* --------------------------------- CLASSES -------------------------------- */
export const getClasses = async () => Request.get(allSchoolClasses());

export const getClassArmStudents = async (id) =>
  Request.get(classArmStudents(id));

export const getClassArmSubjects = async (id) =>
  Request.get(classArmSubjects(id));

export const getClassArmsByClass = async (id) =>
  Request.get(fetchClassArmsByClassId(id));

/* -------------------------------- SUBJECTS -------------------------------- */

export const getSubjects = async (id) => Request.get(allSubjects(id));

export const getLevels = async () => Request.get(getClassLevels());

export const getSubjectClasses = async (id) => Request.get(allClasses(id));

export const getSchoolTerm = async (id) => Request.get(getClassTerms(id));

export const assignClassToSubject = async (items) =>
  Request.post(assignClassesToSubject(), items);

export const removeClassFromSubject = async (items) =>
  Request.post(removeClassesFromSubject(), items);

/* -------------------------------- LESSONS -------------------------------- */

export const getSubjectWithLessonCount = async () =>
  Request.get(SubjectWithLessonCount());

export const updateTimeline = async (items, id) =>
  Request.put(UpdateLessonTime(id), items);

export const AddNewLesson = async (items, id) =>
  Request.post(AddLessons(id), items);

export const AddNewLessonArms = async (items, id) =>
  Request.post(AddLessonsArms(id), items);

export const DeleteLesson = async (id) => Request.delete(deleteLesson(id));

export const EditLessonStatus = async (id, status) =>
  Request.put(changeStatus(id, status));

export const generateLesson = async (items) =>
  Request.post(generationLesson(), items);

export const addGeneratedLesson = async (items, id) =>
  Request.post(addGenerated(id), items);

export const fetchLessons = async (id) => Request.get(getLessons(id));

export const fetchGenerated = async (id) => Request.get(getGenerated(id));

export const getPromptAddition = async (items, id) =>
  Request.post(promptGenerated(id), items);

export const editGenerateLesson = async (items, id) =>
  Request.put(getGenerated(id), items);

export const fetchTemplate = async (id) => Request.get(lessonTemplate(id));

export const uploadFile = async (items, id) => Request.put(saveFile(id), items);

export const editSlide = async (items, id) =>
  Request.put(editSlideInfo(id), items);

/* -------------------------------- STUDENTS -------------------------------- */
export const inviteBulkStudents = async (data) =>
  Request.post(inviteBulkStudentsUrl(), data);
export const inviteStudents = async (data) =>
  Request.post(studentInviteUrl(), data);

/* -------------------------------- TEACHERS -------------------------------- */
export const getSchoolTeachers = async (id) =>
  Request.get(fetchSchoolTeachers(id));

/* -------------------------------- library -------------------------------- */
export const getTioClasses = async () => Request.get(fetchDefaultClass());
export const getLibraryBooks = async () => Request.get(fetchLibraryBooks());
export const addToCart = async (data) => Request.post(addCart(), data);
export const removeFromCart = async (id) => Request.delete(removeCart(id));
export const getCartData = async () => Request.get(fetchCart());
export const addBookToSchool = async (data) =>
  Request.post(addBooksToSch(), data);
export const getSubBooks = async (id) => Request.get(fetchSubBooks(id));
export const clearSchCart = async () => Request.delete(clearCart());
export const removeLibraryBook = async (id) =>
  Request.delete(removeSubjectBook(id));
