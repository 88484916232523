export const classes = [
  {
    id: 1,
    class: "Primary 6",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 2,
    class: "Primary 2",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 3,
    class: "Primary 3",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 4,
    class: "Primary 4",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 5,
    class: "Primary 5",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 6,
    class: "Primary 6",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 7,
    class: "Nursery 3",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 8,
    class: "Nursery 2",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
  {
    id: 9,
    class: "Nursery 1",
    sub: ["P6A", "P6B", "P6C", "P6D"],
  },
];


export const classData = [
  {
    className: "Primary 6",
    subNames: [
      "All Arms",
      "Primary 6A",
      "Primary 6B",
      "Primary 6C",
      "Primary 6D",
    ],
  },
  {
    className: "Primary 5",
    subNames: [
      "All Arms",
      "Primary 5A",
      "Primary 5B",
      "Primary 5C",
      "Primary 5D",
    ],
  },
  {
    className: "Primary 4",
    subNames: [
      "All Arms",
      "Primary 4A",
      "Primary 4B",
      "Primary 4C",
      "Primary 4D",
    ],
  },
  {
    className: "Primary 3",
    subNames: [
      "All Arms",
      "Primary 3A",
      "Primary 3B",
      "Primary 3C",
      "Primary 3D",
    ],
  },
  {
    className: "Primary 2",
    subNames: [
      "All Arms",
      "Primary 2A",
      "Primary 2B",
      "Primary 2C",
      "Primary 2D",
    ],
  },
  {
    className: "Primary 1",
    subNames: [
      "All Arms",
      "Primary 1A",
      "Primary 1B",
      "Primary 1C",
      "Primary 1D",
    ],
  },
];

export const teachersData = [
  {
    name: "Micheal Etim",
  },
  {
    name: "Micheal Etimed",
  },
  {
    name: "Micheal Etim",
  },
  {
    name: "Micheal Etim",
  },
  {
    name: "Micheal Etim",
  },
];

export const fullNames = [
  "John Doe",
  "Jane Smith",
  "Michael Johnson",
  "Emily Brown",
  "Daniel Davis",
  "Olivia Wilson",
  "Matthew Taylor",
  "Ava Martinez",
  "David Anderson",
  "Sophia Thomas",
  "Andrew Jackson",
  "Mia White",
  "Joseph Harris",
  "Charlotte Clark",
  "William Lewis",
  "Abigail Turner",
  "James Lee",
  "Harper Scott",
  "Benjamin Walker",
  "Ella Hall",
  "Daniel Green",
  "Grace Adams",
  "Alexander Baker",
  "Victoria Young",
  "Henry King",
  "Elizabeth Wright",
  "Jacob Hill",
  "Scarlett Phillips",
  "David Carter",
  "Chloe Allen",
  "Samuel Cook",
  "Lucy Murphy",
  "Jackson Parker",
  "Anna Garcia",
  "Sebastian Martinez",
  "Lily Rodriguez",
  "Aiden Sanchez",
  "Nora Rivera",
  "Owen Johnson",
  "Sofia Torres",
  "Elijah Ramirez",
  "Avery Long",
  "Caleb Reed",
  "Ellie Russell",
  "Levi Coleman",
  "Zoey Morris",
  "Gabriel Ward",
  "Hannah Brooks",
  "Matthew Ross",
  "Addison Foster",
  "Ryan Perry",
  "Aria Powell",
];

export const subjects = [
  "English Language",
  "Mathematics",
  "Physics",
  "Chemistry",
  "Biology",
  "Government",
  "Economics",
  "Geography",
  "Literature-in-English",
  "Civic Education",
  "History",
  "Commerce",
  "Accounting",
  "Agricultural Science",
  "Further Mathematics",
  "Technical Drawing",
  "Computer Science",
  "Fine Arts",
  "Music",
  "Physical Education",
  "French",
  "Yoruba",
  "Igbo",
  "Hausa",
];




export const subjectData = [
  {
    name: "English",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Computer Science",
  },
  {
    name: "Music",
  },
  {
    name: "Accounting",
  },
];