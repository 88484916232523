import React, { FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addBookToSchool, clearSchCart, removeFromCart } from "../../../../../api";
import { alertActions } from "../../../../../components/alertActions";
import {
  DotsSpinner,
  RolerLoader,
} from "../../../../../utilities/Loaders/Loader";
import { PrimaryButton } from "../../../../../components/Buttons";
import { EmptyCart } from "../../../../../utilities/EmptyState";

interface Props {
  updateCart: () => void;
  close: () => void;
}
const CartModal: FC<Props> = ({ close, updateCart }) => {
  const cart = useSelector((state: any) => state.library.cart);
  const [isBusy, setIsBusy] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [clearing, setClearing] = useState(false)
  const [selectedId, setSelectedId] = useState(0);
  const deleteItem = (id: number) => {
    setSelectedId(id);
    setIsBusy(true);
    removeFromCart(id)
      .then((res) => {
        if (!res.error) {
          alertActions.success(res.message);
          setIsBusy(false);
          updateCart();
        } else {
          setIsBusy(false);
          alertActions.error(res.message);
        }
      })
      .catch((err) => {
        setIsBusy(false);
        alertActions.error(err.data.message);
      });
  };
  const addToSchool = () => {
    setIsAdding(true);
    const payload = {
      cart: cart.map((item: any) => item.item),
    };
    addBookToSchool(payload)
      .then((res) => {
        if (!res.error) {
          alertActions.success(res.message);
          setIsAdding(false);
          updateCart();
          close();
        } else {
          setIsAdding(false);
          alertActions.error(res.message);
        }
      })
      .catch((err) => {
        setIsAdding(false);
        alertActions.error(err.data.message);
      });
  };
  const clearThisCart = () => {
    setClearing(true)
    clearSchCart()
      .then((res) => {
        if (!res.error) {
          alertActions.success(res.message);
          setClearing(false);
          updateCart();
          close();
        } else {
          setClearing(false);
          alertActions.error(res.message);
        }
      })
      .catch((err) => {
        setClearing(false);
        alertActions.error(err.data.message);
      });
  }
  return (
    <>
      <div
        className="fixed z-30 top-0 left-0 w-full h-screen bg-modal"
        onClick={close}
      >
        <div className="flex justify-end h-full w-full">
          <div
            className="w-[30%] h-full bg-white relative animate-cart"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="pt-5 pb-3 px-3 border-b border-[#0000001A] flex items-center justify-between">
              <h3 className="font-medium text-[20px] text-[#030036BD]">
                Cart ({cart.length})
              </h3>
              <div className="cursor-pointer" onClick={close}>
                <IoMdClose className="text-[22px] text-[#030036BD]" />
              </div>
            </div>
            <div className="px-3">
              <div className="flex justify-end pt-5">
                {
                  clearing && <RolerLoader />
                }
                {(!clearing && !!cart.length) && <p className="cursor-pointer border-b-2 border-[#DD2A2A] font-semibold text-[#DD2A2A]" onClick={clearThisCart}>
                  Clear cart
                </p>}
              </div>
              <div className="mt-7 h-[70vh] overflow-y-auto">
                {!!cart.length &&
                  cart.map((item: any) => (
                    <div className="border-b p-2" key={item.id}>
                      <div className="flex justify-between items-center">
                        <p className="font-[500]">
                          {item.more.textbook_naming}
                        </p>
                        <div className="flex items-center gap-x-2">
                          <RiDeleteBin6Line
                            className="cursor-pointer text-xl text-[#DD2A2A]"
                            onClick={() => deleteItem(item.id)}
                          />
                          {isBusy && item.id === selectedId && <RolerLoader />}
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="text-[#87909E] text-[14px] flex gap-x-2">
                          <p>Class:</p><p className="grid lg:grid-cols-4 gap-x-1">{item.item.grades.map((item:any) => <span key={item.id}>{item.naming}</span>)}</p> 
                        </div>
                        <p className="text-[#87909E] text-[14px]">
                          Subject: {item.more?.subject_naming}
                        </p>
                      </div>
                    </div>
                  ))}
                  {
                    !cart.length && <EmptyCart size={240} msg="Cart is empty"/>
                  }
              </div>
            </div>
            <div className="p-[24px] border-t bg-white">
              <PrimaryButton
                text={isAdding ? <DotsSpinner /> : "Add to school"}
                variant="dash"
                onClick={addToSchool}
                invalid={!cart.length}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartModal;
