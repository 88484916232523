import React, { useState, useEffect } from "react";
import DashLayout from "../../../../layouts/DashLayout";
import { configure } from "../../../../services/config";
import { useSelector } from "react-redux";
import { getTeacherClass, getTeacherStudents } from "../../../../services/authServices";
import { alertActions } from "../../../../components/alertActions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { schoolActions } from "../../../../store/schoolSlice";
import CenterModal from "../../../../components/CenterModal";
import StudentDetailsModal from "./components/StudentDetails";
import NameAvatar from "../../../../components/NameAvatar";
import ClassCard from "../../students/components/ClassCard";
import StudentsModal from "../../students/components/StudentsModal";
import { classActions } from "../../../../store/classSlice";

const TeacherStudent = () => {
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const [loading, setLoading] = useState(false);
  const [selectedArm, setSelectedArm] = useState('');
  const [active, setActive] = useState(false);
  const [item, setItem] = useState("");
  const [allClass, setAllClass] = useState<any[]>()
  const [data, setData] = useState<any[]>();
  const [details, setDetails] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const config = configure(userInfo.token);
  const fetchStudents = async () => {
    setLoading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (!localStorage.getItem("token")) {
      setLoading(false);
      alertActions.error("Your session has ended please login");
      navigate("/login");
    }
    await getTeacherStudents(userInfo.id, config)
      .then((res) => {
        if (!res.data.error) {
          dispatch(schoolActions.saveTeacherStudents(res?.data.data));
          setData(res?.data.data);
        }
      })
      .catch((err) => {});
  };
  const fetchTeacherClass = async () => {
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    try {
      const val = await getTeacherClass(userInfo.id, config);
      val === "Network Error" &&
        alertActions.error("Please check Internet connection");

      setAllClass(val?.data?.data);
      const SchoolManagement = {
        classes: val?.data?.data,
      };
      localStorage.setItem(
        "SchoolManagement",
        JSON.stringify(SchoolManagement)
      );
      dispatch(classActions.saveclassInfo(val?.data?.data));
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStudents();
    fetchTeacherClass();
  }, []);

  const toggleTab = (item: any) => {
    setActive(true);
    setItem(item);
  };

  const colors: string[] = [
    "border-rose-500",
    "border-blue-500",
    "border-yellow-500",
    "border-pink-500",
    "border-orange-500",
  ];

  const showDetails = (item: any) => {
    setDetails(true);
    setSelectedItem(item);
  };

  const renderClassTeacher = () => {
    return (
      <div>
        <div>
          <p>Students({data?.length})</p>
        </div>
        <div className="mt-6">
          <div className="grid lg:grid-cols-2 gap-6 lg:gap-10">
            {data &&
              !!data.length &&
              data.map((item, index) => (
                <div
                  className="flex bg-[#F6F6F6] shade p-2 py-4 border-l-[4px] border-[#AE1E56]"
                  key={index}
                  onClick={() => showDetails(item)}
                >
                  <div className="flex gap-x-4 items-center">
                    <div className="w-16 h-16">
                      <NameAvatar name={item.name} />
                    </div>
                    <div>
                      <p className="font-medium text-lg">{item.name}</p>
                      <p className="text-sm text-[#87909E] mt-1">
                        {item.class.naming}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };
  const renderSubjectTeacher = () => {
    return (
      <div className="w-full flex items-start justify-start flex-col mt-[25px]">
        <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[18px]">
          Classes
        </h3>
        <div className="mt-[32px] w-full grid grid-cols-3 gap-[24px]">
          {allClass &&
            allClass.length &&
            allClass?.map((item: any, index: any) => {
              const colorIndex = index % colors.length;
              const color = colors[colorIndex];
              return (
                <ClassCard
                  item={item}
                  create={() => false}
                  viewStudents={() => toggleTab(item)}
                  selectArm={setSelectedArm}
                  key={index}
                  color={color}
                  teacher
                />
              );
            })}
        </div>
      </div>
    );
  };
  return (
    <>
      <DashLayout title="Students">
        {userInfo.teacher_type === "subject"
          ? renderSubjectTeacher()
          : renderClassTeacher()}
      </DashLayout>
      <CenterModal isOpen={details} onClose={() => setDetails(false)}>
        <StudentDetailsModal
          item={selectedItem}
          close={() => setDetails(false)}
          refetch={fetchStudents}
        />
      </CenterModal>
      <CenterModal isOpen={active} onClose={() => setActive(false)}>
        <StudentsModal item={item} arm={selectedArm} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default TeacherStudent;
