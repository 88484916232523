import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { subjectData } from "../../../../data/data";
import { PrimaryButton } from "../../../../components/Buttons";
import { useSelector } from "react-redux";
import CenterModal from "../../../../components/CenterModal";
import { getSchoolTeachersClass } from "../../../../services/authServices";
import AssignClasstoTeacherModal from "./AddClass";
import { configure } from "../../../../services/config";
import LoadingCompo from "../../../../components/loadingCompo";

interface ClassProps {
  close: () => void;
  item: any;
}

const Class: React.FC<ClassProps> = ({ close, item }) => {
  const [subjectModal, setSubjectModal] = useState(false);
  const [teachersclass, setTeachersclass] = useState([]);
  const [nchange, setchange] = useState(1);
  const [loading, setloading] = useState(false);

  const ClassArmsFilter = (id: any) => {
    const classArms = item.ClassArms.filter((arm: any) => arm.ClassId === id);
    return classArms?.map((arm: any) => arm.naming).join(", ");
  };


  const ClassItem = (item: any) => {
    return (
      <div className="w-full px-4 py-3 border border-black border-opacity-20 shadow-md rounded-[8px] flex items-center justify-between mb-[15px]">
        <div className="flex flex-col items-start">
          <h3 className="text-[14px] font-medium text-[#030036] opacity-70">
            {item.naming}
          </h3>
          <p className="text-[#87909E] text-[12px] font-normal ">
            {ClassArmsFilter(item.id)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Class
          </h3>
          <div className="cursor-pointer" onClick={close}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>

        <div className="h-[330px] overflow-y-auto scrollbar-track-blue-200 scrollbar-thumb-blue-500 multi-scrol px-[24px] py-[15px]">
          {loading && <LoadingCompo />}
          {item.Classes?.map((item: any) => (
            ClassItem(item)
          ))}
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text="Add another Class"
            variant="dash"
            onClick={() => {
              setSubjectModal(true);
            }}
          />
        </div>
      </div>

      <CenterModal isOpen={subjectModal} onClose={() => setSubjectModal(false)}>
        <AssignClasstoTeacherModal
          item={item}
          didchange={() => setchange(nchange + 1)}
          close={() => setSubjectModal(false)}
        />
      </CenterModal>
    </>
  );
};

export default Class;
