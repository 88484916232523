import React, { FC, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import { alertActions } from "../../../../components/alertActions";
import { getSubBooks, removeLibraryBook } from "../../../../api";
import { RolerLoader } from "../../../../utilities/Loaders/Loader";
import { EmptyShelf } from "../../../../utilities/EmptyState";

interface Props {
  close: () => void;
  item?: any;
  teacher?: boolean
  refetch: (val:number) => void
}

const BooksModal:FC<Props> = ({item, teacher, close, refetch}) => {
  const [books, setBooks] = useState<any[]>()
  const [isBusy, setIsBusy] = useState(false)
  const [selectedId, setSelectedId] = useState(0);
  const getBooks = () => {
    getSubBooks(item.id)
      .then((res) => {
        if (!res.error) {
          setBooks(res.data);
        } else alertActions.error(res.message);
      })
      .catch((err) => {
        alertActions.error(err.data.message);
      });
  };
  useEffect(() => {    
    getBooks();
  }, []);

  const deleteBook = (id:number) => {
    setSelectedId(id);
    setIsBusy(true)
    removeLibraryBook(id)
    .then((res) => {
        if (!res.error) {
            alertActions.success(res.message)
            getBooks()
            refetch(item.id)
            setIsBusy(false)
        } else{ alertActions.error(res.message); setIsBusy(false)}
      })
      .catch((err) => {
        alertActions.error(err.data.message);
        setIsBusy(false)
      });
  }

  const BookItem = ({item}:{item:any}) => {
    return (
      <div className="w-full px-4 py-3 border border-black border-opacity-20 shadow-md rounded-[8px] flex items-center justify-between mb-[18px]">
        <div className="flex flex-col items-start">
          <h3 className="text-[14px] font-medium text-[#030036] opacity-70">
            {item.naming}
          </h3>
        </div>
        {
          !teacher && <div className="cursor-pointer flex gap-x-1 items-center">
          <RiDeleteBin5Line size={20} className=" text-[#030036] opacity-70" onClick={() => deleteBook(item.id)} />
          {isBusy && item.id === selectedId && <RolerLoader />}
        </div>
        }
      </div>
    );
  };

  return (
    <div className="">
      <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
        <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
          Library Books
        </h3>
        <div className="cursor-pointer" onClick={close}>
          <IoMdClose className="text-[22px] text-[#545454]" />
        </div>
      </div>
      <div className=" p-[24px] border-b-[1px] h-[330px] overflow-y-auto scrollbar-track-blue-200 scrollbar-thumb-blue-500 multi-scrol">
        {(books && !!books.length) && (
          books?.map((item: any, i:number) => (
            <BookItem item={item} key={i} />
          ))
        )}
        {(books && !books.length) && <EmptyShelf size={140} msg="No Books Added"/>}
      </div>
    </div>
  );
};

export default BooksModal;
