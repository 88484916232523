import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { alertActions } from "../../../../components/alertActions";
import {  getTeacherSubjects } from "../../../../services/authServices";
import { schoolActions } from "../../../../store/schoolSlice";
import { classActions } from "../../../../store/classSlice";
import DashLayout from "../../../../layouts/DashLayout";
import SubjectCard from "../../subjects/components/SubjectCard";
import CenterModal from "../../../../components/CenterModal";
import AddSubjectModal from "../../subjects/components/AddSubjectModal";
import ClassesModal from "../../subjects/components/ClassesModal";
import TeachersModal from "../../subjects/components/TeachersModal";
import DeleteSubjectModal from "../../subjects/components/DeleteSubjectModal";
import { configure } from "../../../../services/config";

interface Data {
  id: number;
  name: string;
}
function TeacherSubjects() {
  const [active, setActive] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [item, setItem] = useState("");
  const [allsubject, setallsubject] = useState([]);
  const [subjectModal, setSubjectModal] = useState(false);
  const [teacherModal, setTeacherModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nchange, setchange] = useState(1);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const schoolSubjects = useSelector(
    (state: any) => state.school.schoolSubject
  );


  const config = configure(userInfo.token);
  useEffect(() => {
    setallsubject(schoolSubjects);
    setLoading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (!localStorage.getItem("token")) {
      setLoading(false);
      alertActions.error("Your session has ended please login");
      navigate("/login");
    }
    const fetchSubjects = async () => {
      try {
        //get all school subjects
        const val = await getTeacherSubjects(userInfo.id, config);
        val == "Network Error" &&
          alertActions.error("Please check Internet connection");

        //dispatch school subjects to avoid calling api multiple times
        dispatch(schoolActions.schoolSubjects(val?.data.data));

        //add school and subject info to the already existing information in local storage
        const SchoolManagement = {
          ...JSON.parse(localStorage.getItem("SchoolManagement") || "{}"),
          schoolSubjects: val?.data.data,
        };
        localStorage.setItem(
          "SchoolManagement",
          JSON.stringify(SchoolManagement)
        );

        //dispatch school classes to avoid calling from API multiple times
        dispatch(
          classActions.saveclassInfo(
            JSON.parse(localStorage.getItem("SchoolManagement") || "{}").classes
          )
        );

        setallsubject(val?.data.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    };
    fetchSubjects();
  }, [nchange]);

  const toggleTab = (item: any, tabId: number): void => {
    setActive(true);
    setActiveTab(tabId);
    setItem(item);
  };

  const toggleTeacher = () => {
    setActive(false);
    setTeacherModal(true);
  };

  const colors: string[] = [
    "bg-[#AE1E56]",
    "bg-[#776DB4]",
    "bg-[#148CFA]",
    "bg-[#DDA0DD]",
    "bg-[#681AB0]",
    "bg-[#330072]",
    "bg-[#4F46E5]",
  ];

  const data: Data[] = [
    { id: 1, name: "Item 1" },
    { id: 2, name: "Item 2" },
    { id: 3, name: "Item 3" },
  ];

  return (
    <>
      <DashLayout title="Subjects">
        <div className="w-full mx-auto">
          <div className="w-full flex items-start justify-start flex-col mt-[20px]">
            {allsubject?.length > 0 ? (
              <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[22px]">
                My Subjects (
                {allsubject?.length > 0
                  ? allsubject?.length
                  : schoolSubjects?.length
                  ? schoolSubjects?.length
                  : 0}
                )
              </h3>
            ) : (
              <h3 className="font-inter font-medium text-[#030036] opacity-70 text-[22px]">
                You haven’t added any subjects
              </h3>
            )}
            {/* {loading && <h1>Loading...</h1>} */}

            <div className="mt-[32px] w-full grid grid-cols-3 gap-[24px]">
              {allsubject?.length > 0 &&
                allsubject?.map((item: any, index) => {
                  const colorIndex = index % colors.length;
                  const color = colors[colorIndex];

                  return (
                    <SubjectCard
                      item={item}
                      subject={item.naming}
                      desc={item.description}
                      color={color}
                      del={() => toggleTab(item, 4)}
                      edit={() => toggleTab(item, 1)}
                      openClasses={() => toggleTab(item, 2)}
                      openTeachers={() => toggleTab(item, 3)}
                      teacher
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </DashLayout>
      <CenterModal isOpen={subjectModal} onClose={() => setSubjectModal(false)}>
        <AddSubjectModal
          didchange={() => setchange(nchange + 1)}
          close={() => setSubjectModal(false)}
        />
      </CenterModal>
      <CenterModal isOpen={active} onClose={() => setActive(false)}>
        {activeTab === 1 ? (
          <></>
        ) : activeTab === 2 ? (
          <ClassesModal
            item={item}
            close={() => setActive(false)}
            addNewClass={() => {
              setSubjectModal(true);
              setActive(false);
            }}
            teacher
          />
        ) : activeTab === 3 ? (
          <TeachersModal
          item={item}
            close={() => setActive(false)}
            assignTeachers={() => toggleTeacher()}
            teacher
          />
        ) : activeTab === 4 ? (
          <DeleteSubjectModal item={item} close={() => setActive(false)} />
        ) : (
          <></>
        )}
      </CenterModal>
    </>
  );
}

export default TeacherSubjects;
