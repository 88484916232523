import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { subjects } from "../../../../data/data";
import { PrimaryButton } from "../../../../components/Buttons";
import AddSubjectModal from "../../subjects/components/AddSubjectModal";
import CenterModal from "../../../../components/CenterModal";
import { useSelector } from "react-redux";
import AssignSubjecttoTeacherModal from "./AddSubject";
import { getSchoolTeachersSubject, unassignTeacher } from "../../../../services/authServices";
import { configure } from "../../../../services/config";
import LoadingCompo from "../../../../components/loadingCompo";
import { RiDeleteBin5Line } from "react-icons/ri";
import { alertActions } from "../../../../components/alertActions";
import { loadSchoolTeachers } from "../../../../global/state";

interface SubjectsProps {
  close: () => void;
  item: any;
  didchange: () => void;
}

const Subjects: React.FC<SubjectsProps> = ({ close, item, didchange }) => {
  const [subjectModal, setSubjectModal] = useState(false);
  const [loading, setloading] = useState(false);
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const config = configure(userInfo.token);
  
  const deleteTeacher = async(id:string) => {
    
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    const payload = {
      subject: id,
      teacher: item.Account.id
    }
    try {
      const val = await unassignTeacher(payload, config);
      alertActions.success(val.data.message);
      loadSchoolTeachers();
    } catch (error: any) {}
  }

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Subjects
          </h3>
          <div className="cursor-pointer" onClick={close}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>

        <div className="h-[330px] overflow-y-auto scrollbar-track-blue-200 scrollbar-thumb-blue-500 multi-scrol px-[24px]">
          {loading && <LoadingCompo />}
          {item.Subjects.map((item: any) => (
            <div className="w-full py-3 border border-gray-200 px-[24px] text-[#030036] opacity-70 my-5 rounded-[6px] shadow-md flex justify-between">
              {item.naming}
              <RiDeleteBin5Line size={20} className=" text-[#030036] opacity-70" onClick={() => deleteTeacher(item.id)} />
            </div>
          ))}
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text="Add another Subject"
            variant="dash"
            onClick={() => {
              setSubjectModal(true);
            }}
          />
        </div>
      </div>
      <CenterModal isOpen={subjectModal} onClose={() => setSubjectModal(false)}>
        <AssignSubjecttoTeacherModal
          item={item}
          didchange={() => didchange()}
          close={() => setSubjectModal(false)}
        />
      </CenterModal>
    </>
  );
};

export default Subjects;
