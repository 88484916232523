import React, { useEffect } from "react";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { ROUTES } from "../global/routes";

interface DashLayoutProps {
  children?: React.ReactNode;
  title?: string;
}

const DashLayout: React.FC<DashLayoutProps> = ({ children, title }) => {
  useEffect(()=>{
    let token = localStorage.getItem("token");
    let path = window.location.pathname;
    let appRoutes = Object.values(ROUTES);
    if(token){
      if( !appRoutes.includes(path) ) {
        // Handle 404 Page ish
      }
    }else{
      window.location.replace("http://hellotioo.com/login");
    }
  },[]);
  return (
    <div className="h-screen top-0 overflow-hidden relative grid w-full grid-cols-[220px,1fr] 2xl:grid-cols-[250px,1fr] gap-0 max-w-full">
      <Sidebar />
      <main className="relative w-full max-w-full min-h-screen overflow-auto">
        <Header title={title} />
        <div className="content max-w-screen-2xl">{children}</div>
      </main>
    </div>
  );
};

export default DashLayout;
