import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import {
  InputField,
  MultiSelectField,
  SelectField,
  OptionField,
} from "../../../../components/Inputs";
import { parseDataForMultiSelect } from "../../../../components/multiSelectField";
import { classData, subjectData } from "../../../../data/data";
import { PrimaryButton } from "../../../../components/Buttons";
import { useSelector } from "react-redux";
import { generateInvite, inviteUser } from "../../../../services/authServices";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import { ClassArmSelectField } from "../../../../components/ClassArmselect";
import CenterModal from "../../../../components/CenterModal";
import DiscardWarning from "../../../../components/DiscardWarning";
import { RadioSelect } from "../../../../components/RadioSelectField";
import { SelectData } from "../../../../components/selectField";
import { getClasses } from "../../../../api";
import { useDispatch } from "react-redux";
import { classActions } from "../../../../store/classSlice";

interface InviteTeachersProps {
  close: () => void;
  didchange: () => void;
}

const data = [
  {
    id: 1,
    name: "Subject Teacher",
    type: "subject",
  },
  {
    id: 2,
    name: "Class Teacher",
    type: "class",
  },
];
const InviteTeachers: React.FC<InviteTeachersProps> = ({
  close,
  didchange,
}) => {
  const [checkedId, setCheckedId] = useState("");
  const [teacherType, setTeacherType] = useState("");
  const [copied, setCopied] = useState(false);
  const [showLink, setShowLink] = useState(true);
  const [loading, setloading] = useState(false);
  const [haschanged, sethaschanged] = useState(false);
  const [active, setActive] = useState(false);

  const [tname, setTname] = useState("");
  const [temail, setTemail] = useState("");
  const [invlink, setinvLink] = useState("");
  const [classarr, setclassarr] = useState<any>([]);
  const [classArms, setClassArms] = useState<any>([]);
  const [teacherArm, setTeacherArm] = useState<any>("");
  const [subjectData, setSubjectData] = useState([]);
  const classInfo = useSelector((state: any) => state.class.classInformation);
  const subjectInfo = useSelector((state: any) => state.school.schoolSubject);
  const school = useSelector((state: any) => state.school.schoolManagementInfo);
  const token = useSelector((state: any) => state.auth.token);
  const [allClassArms, setAllClassArms] = useState<any>([]);
  const [parsedClassArms, setParsedClassArms] = useState<any>([]);
  const [parsedClassSubjects, setParsedClassSubjects] = useState<SelectData[]>(
    []
  );
  const dispatch = useDispatch();

  const handleSelectedDataChange = (data1: any, data2: any) => {
    setclassarr(data1);
    setClassArms(data2);
  };

  const filterClassArmSubjects = () => {
    let list: any[] = [];
    classInfo.forEach((classData: any) => {
      const selectedArms = classData.ClassArms.filter((arm: any) =>
        classArms.includes(arm.id)
      );
      selectedArms.forEach((arm: any) => {
        list = [...list, ...arm.Subjects];
      });
    });
    const parsed = list.length
      ? list.filter((value, index) => {
        const listAsNames = list.map((item)=> item.naming);
        return listAsNames.indexOf(value.naming) === index;
      }).map((item) => ({
          label: item.naming,
          id: item.id,
        }))
      : [];
    setParsedClassSubjects(parsed);
  };

  const loadClasses = () => {
    getClasses().then((response) => {
      dispatch(classActions.saveclassInfo(response?.data));
    });
  };

  const generateClassArmsList = () => {
    let list: any[] = [];
    classInfo.forEach((classData: any) => {
      classData.ClassArms.forEach((arm: any) => {
        list = [...list, arm];
      });
    });
    setAllClassArms(list);
    setParsedClassArms(() => list.map(item => ({
      id : item.id,
      label : item.naming
    })));
  };


  const handleSelectSubject = (selected: any) => {
    setSubjectData(selected);
  };

  const handleClassArmSelect = (data : any) => {
    setClassArms(() => [data]);
    let parentClass = classInfo.filter((clss:any)=>{
      return clss.ClassArms.filter((arm:any) => arm.id == data).length;
    })[0];
    setclassarr(() => [parentClass.id]);
    setTeacherArm(data);
  }

  const parsedClassInfo = parseDataForMultiSelect(
    classInfo,
    ["naming", "naming"],
    ["ClassArms"]
  );

  const docheckedit = () => {
    if (checkedId) {
      setActive(true);
    } else {
      close();
    }
  };

  const config = configure(token);
  // console.log(config);
  const handleCheckboxChange = (name: any) => {
    setCheckedId(name);
    setTeacherType(name.type);
    // handleInvitelink();
  };

  useEffect(() => {
    if (classarr.length > 0 && subjectData.length > 0) {
      handleInvitelink();
    }
  }, [classarr, classArms, subjectData]);

  useEffect(() => {
    setSubjectData([]);
    filterClassArmSubjects();
  }, [classarr, classArms, classInfo]);

  useEffect(() => {
    loadClasses();
    generateClassArmsList();
  }, []);

  const handleInvitelink = async () => {
    const linkObj = {
      account_type: "teacher",
      classes: classarr.map((el: any) => Number(el)),
      classarms: classArms.map((el: any) => Number(el)),
      subjects: subjectData.map((el: any) => Number(el)),
      schoolId: school.schoolManagementId,
    };
    console.log(linkObj);
    const generate = await generateInvite(linkObj, config);
    setinvLink(generate?.data?.data?.link);
    // console.log(generate);
  };

  const textToCopy = invlink;
  const handleCopyButtonClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        setCopied(false);
      });
  };

  const handleInvite = async () => {
    if(!(tname && temail && classarr.length && subjectData.length)){
      return;
    }
    setloading(true);
    setShowLink(!showLink);
    const inviteObj = {
      name: tname,
      email: temail,
      account_type: "teacher",
      password: "",
      flag: "invited",
      teacher_type: teacherType,
      classes: classarr.map((el: any) => Number(el)),
      classarms: classArms.map((el: any) => Number(el)),
      Subjects: subjectData.map((el: any) => Number(el)),
      schoolId: school.schoolManagementId,
    };

    try {
      const invite = await inviteUser(inviteObj, config);
      if (invite.status == 200) {
        alertActions.success("Invitation sent successfully");
        setloading(false);
        close();
        didchange();
      }
    } catch (error) {
      setloading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Invite Teachers
          </h3>
          <div className="cursor-pointer" onClick={docheckedit}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="p-[24px] border-b-[1px] h-[390px] overflow-y-auto scrollbar-track-blue-200 scrollbar-thumb-blue-500 multi-scrol">
          <InputField
            type="text"
            label="Full Name (Optional)"
            placeholder=""
            name="fullname"
            variant="white"
            value={tname}
            onChange={(e: any) => {
              setTname(e.target.value);
              sethaschanged(true);
            }}
          />
          <InputField
            type="email"
            label="Email"
            placeholder=""
            name="email"
            variant="white"
            value={temail}
            onChange={(e: any) => {
              setTemail(e.target.value);
              sethaschanged(true);
            }}
          />
          <OptionField
            label="I am Inviting a"
            checkedId={checkedId}
            onCheckboxChange={handleCheckboxChange}
            variant="white"
            data={data}
          />
          {teacherType == "subject" && (
            <MultiSelectField
              label="Assign classes"
              variant="white"
              data={parsedClassInfo}
              onSelectedDataChange={handleSelectedDataChange}
            />
          )}

          {teacherType == "class" && (
            <RadioSelect
              label="Assign to class"
              variant="white"
              data={parsedClassArms}
              checkedId={teacherArm}
              onCheckboxChange={handleClassArmSelect}
            />
          )}
          <SelectField
            label="Assign Subjects"
            variant="white"
            data={parsedClassSubjects}
            onSelectedDataChange={handleSelectSubject}
          />
        </div>

        <div className="p-[24px]">
          <PrimaryButton
            text={loading ? "Loading..." : "Invite"}
            variant="dash"
            onClick={handleInvite}
            invalid={!(classarr.length && subjectData.length && temail && tname)}
          />
          {showLink && (
            <div className="w-full pt-4 ">
              <h3 className="text-[#030036] opacity-70 text-base font-normal underline">
                Teachers can also join with the class link
              </h3>
              <div className="rounded-[8px] border-[2px] border-[#E3E2FF] px-[10px] py-2 mt-2 flex items-center w-full justify-between">
                <h4 className="text-[#030036] opacity-70 text-base font-small">
                  {textToCopy}
                </h4>
                {copied ? (
                  <div
                    className="text-green-400 cursor-pointer text-[12px] font-medium"
                    onClick={handleCopyButtonClick}
                  >
                    Copied
                  </div>
                ) : (
                  <div
                    className="text-[#4F46E5] cursor-pointer text-[12px] font-medium"
                    onClick={handleCopyButtonClick}
                  >
                    Copy link
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <DiscardWarning handleAction={close} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default InviteTeachers;
