import React, { useState, useEffect } from "react";
import { PrimaryButton } from "../../../../components/Buttons";
import { InputField, PhoneInputField } from "../../../../components/Inputs";
import { alertActions } from "../../../../components/alertActions";
import {
  acceptMagicLinkStudent,
  acceptStudentsInvite,
  getInvitedStudent,
  getInvitedTeacher,
  getStudentInviteInfo,
} from "../../../../services/authServices";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { CountryData } from "react-phone-input-2";
import { useSelector } from "react-redux";
import { AnyNsRecord } from "dns";
import { configure } from "../../../../services/config";

const url = "http://api.praxeum.ai/api/";

interface StepOneProps {
  onNext?: any;
  onPrev?: any;
}

interface InitValues {
  name: string;
  email: string;
  arm?: string;
  schoolId?: string;
  class?: string;
  phone: string;
  password: string;
  cpassword?: string;
  account_type?: string;
  accountId?: string;
}

interface InitState {
  values: InitValues;
  loading: boolean;
}

interface MobileNumber {
  countryCode: string;
  phoneNumber: string;
}

const initValues: InitValues = {
  name: "",
  email: "",
  arm: "",
  schoolId: "",
  class: "",
  phone: "",
  password: "",
  cpassword: "",
  account_type: "",
  accountId: "",
};
interface InitState {
  values: InitValues;
  loading: boolean;
}
const initState: InitState = {
  values: initValues,
  loading: false,
};

const StepOne: React.FC<StepOneProps> = ({ onNext, onPrev }) => {
  const [state, setstate] = useState(initState);
  const [token, settoken] = useState<any>("");
  const [arm, setarm] = useState<any>();
  const { values, loading } = state;
  const [phoneNumber, setPhoneNumber] = useState<MobileNumber>({
    countryCode: "",
    phoneNumber: "",
  });
  const [invitedStudentsInfo, setinvitedStudentsInfo] = useState<{} | any>({});
  const { id } = useParams();
  const { hash } = useLocation();

  const userInfo = useSelector((state: any) => state.auth.userInformation);

  const config = configure(userInfo.token);

  const isMagicLink = () => {
    return id && id.length < 15;
  };

  useEffect(() => {
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    settoken(localStorage.getItem("token"));
    const fetchInvitedStudent = async () => {
      try {
        const invitedStudent = !isMagicLink()
          ? await getInvitedStudent(id, config)
          : await getStudentInviteInfo(id, config);
        console.log(invitedStudent);
        setinvitedStudentsInfo(invitedStudent?.data.data);

        setstate((prev) => ({
          ...prev,
          values: {
            name: isMagicLink()
              ? invitedStudent?.data.data.name
              : invitedStudent?.data.data.account.name,
            email: isMagicLink()
              ? invitedStudent?.data.data.email
              : invitedStudent?.data.data.account.email,
            phone: "",
            password: "",
            cpassword : "",
            class : isMagicLink() ? invitedStudent?.data.data.arm?.naming : invitedStudent.data.data.class_arm?.naming,
            ...(isMagicLink() ? { 
              schoolId : invitedStudent.data.data.schoolId,
              account_type: "student",
              arm : invitedStudent?.data.data.arm.id
            } : {
              accountId: id,
            }),
          },
        }));
      } catch (error) {}
    };
    fetchInvitedStudent();
  }, []);
  console.log(values);
  const handleChange = ({ target }: any) => {
    setstate((prev) => ({
      ...prev,
      values: { ...prev.values, [target.name]: target.value },
    }));
  };

  const setStateLoading = (val: boolean) =>
    setstate((prev) => ({ ...prev, loading: val }));

  const validate = async () => {
    setStateLoading(true);
    if (!values.cpassword || values.cpassword !== values.password) {
      alertActions.error("Passwords and confirm password is not the same");
      setStateLoading(false);
      return;
    }
    if (values.password.length < 6) {
      alertActions.warning("Password should be more than 6 Characters");
      setStateLoading(false);
      return;
    }

    values.phone = phoneNumber.phoneNumber;
    delete values.cpassword;

    try {
      const acceptInvite = isMagicLink()
        ? await acceptMagicLinkStudent(values)
        : await acceptStudentsInvite(values);
      if (acceptInvite.status == 200) {
        isMagicLink()
          ? alertActions.success("Account created successfully")
          : alertActions.success("Invite Accepted successfully");
        setStateLoading(false);
      }
    } catch (error: any) {
      console.log(error);
      setStateLoading(false);
    }
  };

  const handleMobileChange = (value: string, country: CountryData) => {
    setPhoneNumber({
      countryCode: country.dialCode,
      phoneNumber: value,
    });
  };

  return (
    <div className="w-full h-full">
      <div className="w-full flex items-center justify-center">
        <div className="pb-3 border-b-2 border-[#4F46E5] w-[296px]">
          <h3 className="font-semibold text-[#4F46E5] text-[22px] text-center">
            Student sign Up
          </h3>
          <p className="text-center text-[#030036] font-normal text-sm pt-2">
            Create an account to get started on{" "}
            <span className="text-[#4F46E5] font-bold">Nimo</span>
          </p>
        </div>
      </div>
      <div className="my-[30px] w-full">
        <InputField
          label="Full Name"
          placeholder="Enter Full Name"
          name="name"
          type="text"
          value={values.name}
          // value={teach}
          onChange={handleChange}
        />
        <InputField
          label="Email"
          name="email"
          placeholder="Enter Email "
          type="email"
          value={values.email}
          onChange={handleChange}
        />
        <InputField
          label="Class"
          name="school"
          placeholder="Enter Class"
          type="text"
          onChange={handleChange}
          value={values.class}
        />
        <PhoneInputField
          label="Phone number"
          placeholder="903 558 1238"
          value={phoneNumber.phoneNumber}
          onChange={handleMobileChange}
        />
        <div className="flex items-center gap-3">
          <div className="w-full">
            <InputField
              label="Password"
              name="password"
              placeholder="Enter Password"
              type="password"
              value={values.password}
              onChange={handleChange}
            />
          </div>
          <div className="w-full">
            <InputField
              label="Confirm Password"
              name="cpassword"
              placeholder="Confirm Password"
              type="password"
              value={values.cpassword}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <PrimaryButton
        text={loading ? "Loading..." : "Accept Invite"}
        onClick={validate}
      />
      <div className="mt-[32px]">
        <h4 className="text-center text-[#030036] font-normal cursor-pointer">
          Already have an account?{" "}
          <a href="/login" className="text-[#4F46E5] font-bold">
            Login
          </a>
        </h4>
      </div>
    </div>
  );
};

export default StepOne;
