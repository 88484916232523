import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import {
  InputField,
  TextAreaField,
  SelectField,
} from "../../../../components/Inputs";
import { PrimaryButton } from "../../../../components/Buttons";
import { teachersData } from "../../../../data/data";
import { ClassArmSelectField } from "../../../../components/ClassArmselect";
import { useSelector } from "react-redux";
import {
  bindSubject___Teacher,
  bindSubject___class,
} from "../../../../services/authServices";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import CenterModal from "../../../../components/CenterModal";
import DiscardWarning from "../../../../components/DiscardWarning";

interface AssignclasssToteacher {
  close: () => void;
  item: any;
  didchange: () => void;
}

const AssignSubjecttoTeacherModal: React.FC<AssignclasssToteacher> = ({
  close,
  item,
  didchange,
}) => {
  const [subjectlistdata, setSubjectlistdata] = useState([]);
  const [token, settoken] = useState<any>("");
  const [loading, setloading] = useState<any>("");
  const [haschanged, sethaschanged] = useState(false);
  const [active, setActive] = useState(false);

  const [subjectData, setSubjectData] = useState<any>([]);

  const allclassData = useSelector(
    (state: any) => state.class.classInformation
  );

  const schoolSubjects = useSelector((state:any) => state.school.schoolSubject);

  const handleSelectedDataChange = (
    selectedClasses: string[],
    selectedSubNames: string[]
  ) => {
    // You can perform any additional logic or dispatch an action with the selected data here
  };
  useEffect(() => {
    settoken(localStorage.getItem("token"));
    const teacherArms = item.ClassArms.map((arm:any) => arm.id);
    setSubjectData(() => {
      return schoolSubjects.filter((subject :any) => {
        const subjectArms = subject.ClassArms.map((arm:any) => arm.id);
        return teacherArms.filter((arm:any) => subjectArms.includes(arm)).length > 0;
      })
    });
  }, [schoolSubjects]);

  const docheckedit = () => {
    if (haschanged) {
      setActive(true);
    } else {
      close();
    }
  };
  const handleSubData = (data: any) => {
    setSubjectlistdata(data);
    data.length > 0 && sethaschanged(true);
  };
  const handleBindClass = async () => {
    setloading(true);
    const config = configure(token);

    const bindObj = {
      teacher: item.Account.id,
      subject: subjectlistdata,
    };
    try {
      const bind = await bindSubject___Teacher(bindObj, config);
      if (bind.status == 200) {
        alertActions.success("Class assigned to subject successfully");
        close();
        didchange();
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Assign Subject
          </h3>
          <div className="cursor-pointer" onClick={docheckedit}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="p-[24px] border-b-[1px]">
          {/* <InputField
          type="text"
          label="Subject Title"
          placeholder=""
          name="tags"
          variant="white"
        /> */}

          <ClassArmSelectField
            label="Subject"
            variant="white"
            data={subjectData}
            onSelectedDataChange={handleSubData}
          />
          {/* <TextAreaField variant="white" label="Subject Description (optional)" /> */}
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text={loading ? "loading.." : "Save"}
            variant="dash"
            onClick={handleBindClass}
          />
        </div>
      </div>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <DiscardWarning handleAction={close} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default AssignSubjecttoTeacherModal;
