import React, { useState, useEffect } from "react";
import DashLayout from "../../../../layouts/DashLayout";
import { BiSearch } from "react-icons/bi";
import { IoCartOutline } from "react-icons/io5";
import LibraryCard from "./components/LibraryCard";
import CartModal from "./components/CartModal";
import { getCartData, getLibraryBooks } from "../../../../api";
import { alertActions } from "../../../../components/alertActions";
import { categoryOptions } from "../../../../utilities/hardData";
import { libraryActions } from "../../../../store/librarySlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const LibraryIndex = () => {
  const [books, setBooks] = useState([]);
  const [bookData, setBookData] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const cart = useSelector((state:any) => state.library.cart)
  const dispatch = useDispatch()
  const getData = () => {
    getLibraryBooks()
      .then((res) => {
        if (!res.error) {
          setBooks(res.data);
          setBookData(res.data);
        } else alertActions.error(res.message);
      })
      .catch((err) => {
        alertActions.error(err.data.message);
      });
  };
  const getCart = () => {
    getCartData()
    .then((res) => {
      if(!res.error){
        dispatch(libraryActions.saveCart(res.data))
      } else alertActions.error(res.message);
    })
    .catch((err) => {
      alertActions.error(err.data.message);
    });
  }
  useEffect(() => {
    getData();
    getCart()
  }, []);
  const textBookOptions = () => {
    const matchingLogs = categoryOptions?.filter((obj) => {
      // You can specify your filter condition here.
      return bookData?.some((obj2: any) => obj.id === obj2.subject_category);
    });
    return matchingLogs;
  };
  const FilterBooks = (id: any) => {
    if (Number(id) === 0) {
      setBooks(bookData);
    } else {
      setBooks(
        bookData.filter((where: any) => where.subject_category === Number(id))
      );
    }
  };
  const handleSearchInputChange = (e: any) => {
    setSearchQuery(e.target.value);
    const filtered = bookData.filter((item: any) =>
      item.naming.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setBooks(filtered);
  };
  return (
    <>
      <DashLayout title="">
        <div>
          <div>
            <p className="font-[500]">Home/Library</p>
          </div>
          <div className="mt-7 flex items-center justify-between">
            <div>
              <select
                onChange={(e) => FilterBooks(e.target.value)}
                className="border-0 text-xl outline-none py-2"
              >
                <option value={0}>Select categories</option>
                {textBookOptions().map((itm, i) => (
                  <option value={itm.id} key={i}>
                    {itm.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-x-3 items-center">
              <div className="flex items-center overflow-hidden border rounded-[8px] border-[#87909E] xl:w-[400px]">
                <BiSearch className="text-2xl mx-2" />
                <input
                  type="search"
                  placeholder="Search textbooks"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  className="outline-none w-full p-2"
                />
              </div>
              <div
                className="relative hover:scale-105 duration-100 cursor-pointer"
                onClick={() => setShowCart(true)}
              >
                {
                  !!cart.length &&
                  <p className="absolute -top-1 -right-1 w-6 h-6 rounded-[30px] grid place-content-center bg-[#4F46E5] text-white">
                  {cart.length}
                </p>
                }
                <IoCartOutline className="text-5xl mb-1" />
              </div>
            </div>
          </div>
          <div className="py-10">
            <LibraryCard data={books} updateCart={getCart}/>
          </div>
        </div>
      </DashLayout>
      {showCart && <CartModal close={() => setShowCart(false)} updateCart={getCart}/>}
    </>
  );
};

export default LibraryIndex;
