import React, { useState } from "react";
import AuthLayout from "../../../layouts/AuthLayout";
import StepOne from "./steps/StepOne";

export default function Teacher() {
  const [step, setStep] = useState(1);

  const handleNext = (data: any) => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      default:
        break;
    }
  };

  let currentStep;

  switch (step) {
    case 1:
      currentStep = <StepOne onNext={handleNext} />;
      break;
    default:
      break;
  }

  return <AuthLayout>{currentStep}</AuthLayout>;
}
