export const categoryOptions = [
    { id: 1, name: "Mathematics" },
    { id: 2, name: "English" },
    { id: 3, name: "Basic Science" },
    { id: 4, name: "Quantitative Reasoning" },
    { id: 5, name: "Verbal Reasoning" },
    { id: 6, name: "CRK" },
    { id: 7, name: "History" },
    { id: 8, name: "Civic Education" },
    { id: 9, name: "Security" },
    { id: 10, name: "Business Studies" },
    { id: 11, name: "Visual Arts" },
    { id: 12, name: "Geography" },
  ];