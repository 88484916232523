import React, { useState, useEffect, } from "react";
import { IoMdClose } from "react-icons/io";
import {
  InputField,
  TextAreaField,
  SelectField,
  MultiSelectField,
} from "../../../../components/Inputs";
import { PrimaryButton } from "../../../../components/Buttons";
import { teachersData } from "../../../../data/data";
import { ClassArmSelectField } from "../../../../components/ClassArmselect";
import { useSelector } from "react-redux";
import {
  bindClass___Teacher,
  bindSubject___Teacher,
  bindSubject___class,
} from "../../../../services/authServices";
import { configure } from "../../../../services/config";
import { alertActions } from "../../../../components/alertActions";
import { parseDataForMultiSelect } from "../../../../components/multiSelectField";
import CenterModal from "../../../../components/CenterModal";
import DiscardWarning from "../../../../components/DiscardWarning";
import { useDispatch } from "react-redux";
import { schoolActions } from "../../../../store/schoolSlice";
import { getSchoolTeachers } from "../../../../api";
import { loadSchoolTeachers } from "../../../../global/state";

interface AssignclasssToteacher {
  close: () => void;
  item: any;
  didchange: () => void;
}

const AssignClasstoTeacherModal: React.FC<AssignclasssToteacher> = ({
  close,
  item,
  didchange,
}) => {
  const [subjectlistdata, setSubjectlistdata] = useState([]);
  const [token, settoken] = useState<any>("");
  const [loading, setloading] = useState<any>("");
  const [haschanged, sethaschanged] = useState(false);
  const [active, setActive] = useState(false);

  const [allclasseesarmData, setallclasseesarmData] = useState<any | []>([]);
  const [classarr, setclassarr] = useState<any>([]);
  const [classArmarr, setclassArmarr] = useState([]);
  const allclassData = useSelector(
    (state: any) => state.class.classInformation
  );
  const classInfo = useSelector((state: any) => state.class.classInformation);
  const schoolTeachersinfo = useSelector(
    (state: any) => state.school.teachersInfo
  );
  const dispatch = useDispatch();
  const managementId = useSelector((state: any) => state.school.schoolManagementInfo.schoolManagementId)

  useEffect(() => {
    settoken(localStorage.getItem("token"));
    setallclasseesarmData(
      JSON.parse(localStorage.getItem("SchoolManagement") || "{}").classes
    );
  }, []);

  const parsedClassInfo = parseDataForMultiSelect(
    classInfo,
    ["naming", "naming"],
    ["ClassArms"]
  );
  const handleSelectedDataChange = (data1: any, data2: any) => {
    setclassarr(data1);
    setclassArmarr(data2);
    if (data1.length > 0 || data2.length > 0) {
      sethaschanged(true);
    }
  };


  const docheckedit = () => {
    if (haschanged) {
      setActive(true);
    } else {
      close();
    }
  };

  const handleBindClass = async () => {
    setloading(true);
    const config = configure(token);

    const bindObj = {
      arms: classArmarr,
      teacher_id: item.Account.id,
    };
    
    try {
      const bind = await bindClass___Teacher(bindObj, config);
      if (bind.status === 200) {
        alertActions.success("Class assigned to subject successfully");
        loadSchoolTeachers();
        close();
        didchange();
        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <div className="">
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Assign Class
          </h3>
          <div className="cursor-pointer" onClick={docheckedit}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="p-[24px] border-b-[1px]">
          {/* <InputField
          type="text"
          label="Subject Title"
          placeholder=""
          name="tags"
          variant="white"
        /> */}

          <MultiSelectField
            label=" Classes"
            variant="white"
            data={parsedClassInfo}
            onSelectedDataChange={handleSelectedDataChange}
          />
          {/* <TextAreaField variant="white" label="Subject Description (optional)" /> */}
        </div>
        <div className="p-[24px]">
          <PrimaryButton
            text={loading ? "loading.." : "Save"}
            variant="dash"
            onClick={handleBindClass}
          />
        </div>
      </div>
      <CenterModal
        isOpen={active}
        onClose={() => setActive(false)}
        variant={"small"}
      >
        <DiscardWarning handleAction={close} close={() => setActive(false)} />
      </CenterModal>
    </>
  );
};

export default AssignClasstoTeacherModal;
