import React, { FC, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { BsPencil, BsTelephone } from 'react-icons/bs'
import { AiOutlineMail } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import NameAvatar from "../../../../../components/NameAvatar";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { configure } from "../../../../../services/config";
import { useSelector } from "react-redux";
import { alertActions } from "../../../../../components/alertActions";
import { deleteStudent } from "../../../../../services/authServices";

interface Props {
  close: () => void;
  refetch: () => void;
  item: any;
}
const StudentDetailsModal: FC<Props> = ({ close, item, refetch }) => {
  const userInfo = useSelector((state: any) => state.auth.userInformation);
  const navigate = useNavigate();
  const config = configure(userInfo.token);
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const deleteThisStudent = async(id:any) => {
    setLoading(true);
    if (!userInfo.token) {
      config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }
    if (!localStorage.getItem("token")) {
      setLoading(false);
      alertActions.error("Your session has ended please login");
      navigate("/login");
    }
    await deleteStudent(id, config)
    .then((res) => {
      if(!res.data.error){
        alertActions.success(res.data.message);
        close()
        refetch()
      }alertActions.error(res.data.message);
    })
    .catch((err)=> {})
  }
  return (
    <>
      <div>
        <div className="flex w-full items-center justify-between border-b-[1px] py-4 px-[24px]">
          <h3 className="font-medium text-[20px] text-[#030036] opacity-70">
            Student Profile
          </h3>
          <div className="cursor-pointer" onClick={close}>
            <IoMdClose className="text-[22px] text-[#545454]" />
          </div>
        </div>
        <div className="mt-5 px-[24px] pb-5">
          <div className="flex gap-x-4 items-center">
            {/* <img src={item.image} alt="avatar" className="w-12" /> */}
            <div className="w-16 h-16">
                    <NameAvatar name={item.name}/>
                    </div>
            <div>
              <p className="font-medium text-lg">{item.name}</p>
              <p className="text-sm text-[#87909E] mt-1">{item.class.naming}</p>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex gap-x-1 items-center">
                <BsPencil className="text-[#030036BD]"/>
                <p className="text-[#030036BD]">Joined {dayjs(item.created_at).format('DD MMM, YYYY')}</p>
            </div>
            <div className="flex gap-x-1 items-center mt-2">
                <BsTelephone className="text-[#030036BD]"/>
                <p className="text-[#030036BD]">{item.phone}</p>
            </div>
            <div className="flex gap-x-1 items-center mt-2">
                <AiOutlineMail className="text-[#030036BD]"/>
                <p className="text-[#030036BD]">{item.email}</p>
            </div>
            <div className="flex gap-x-1 items-center mt-2 cursor-pointer" onClick={() => setShowDelete(true)}>
                <RiDeleteBinLine className={showDelete? "text-red-500" : "text-[#030036BD]"}/>
                <p className={showDelete? "text-red-500" : "text-[#030036BD]"}>Remove Student</p>
            </div>
            {
              showDelete && (
                <div>
                  <p className="text-[#87909E] text-sm mt-2">Are you sure you want to remove this student from {item.class.naming}?</p>
                  <div className="flex gap-x-5 justify-center">
                    <span className="cursor-pointer" onClick={() => setShowDelete(false)}>Cancel</span>
                    <span className="text-red-500 cursor-pointer" onClick={() => deleteThisStudent(item.id)}>OK</span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDetailsModal;
